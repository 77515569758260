import connectify from "../images/connectify.jpg";
import rent from "../images/rent.jpg";
import shoestore from "../images/shoestore.jpg";
import ws from "../images/ws.png";
import imageclassification from "../images/image-classification.jpg";
import spam from "../images/spam.png";
import spring from "../images/spring.png";
import sdlc from "../images/sdlc.png";
import flask from "../images/flask.png";

export const Bio = {
    name: "Saumya Shah",
    roles: [
      "Full Stack Engineer",
      "Software Engineer",
      "Frontend Engineer",
      "Backend Engineer",
    ],
    description:
      "Devoted full-stack developer, focusing on turning innovative ideas into scalable, dependable software solutions. Proficient in both front-end and back-end programming, dedicated to leveraging cutting-edge technologies and clean code to produce user-friendly experiences",
    github: "https://github.com/saumya4751",
    resume:
      "https://drive.google.com/file/d/1zHMY3Fjb0AOcFUR-SkxVW8Mh2pCVvF2a/view?usp=drive_link",
    linkedin: "https://www.linkedin.com/in/saumya4751/",
  };

  export const skills = [
    {
      title: "Languages",
      skills: [
        {
          name: "JavaScript",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png",
        },
        {
          name: "Python",
          image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png",
        },
        {
          name: "Java",
          image: "https://static-00.iconduck.com/assets.00/java-icon-1511x2048-6ikx8301.png",
        },
        {
          name: "HTML",
          image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
        },
        {
          name: "SQL",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/8/87/Sql_data_base_with_logo.png",
        },
      ],
    },
    {
      title: "Frontend",
      skills: [
        {
          name: "React Js",
          image:
            "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
        },
        {
          name: "Redux",
          image:
            "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
        },
        {
          name: "CSS",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
        },
        {
          name: "Bootstrap",
          image:
            "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
        },
        {
          name: "Material UI",
          image:
            "https://mui.com/static/logo.png",
        },
        {
          name: "Zustand",
          image: "https://tsh.io/wp-content/uploads/2023/02/zustand-logo.png"
        }
      ],
    },
    {
      title: "Backend",
      skills: [
        {
          name: "Node.js",
          image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/2560px-Node.js_logo.svg.png",
        },
        {
          name: "Express.js",
          image:
            "https://cdn.icon-icons.com/icons2/2699/PNG/512/expressjs_logo_icon_169185.png",
        },
        {
          name: "Flask",
          image: `${flask}`,
        },
        {
          name: "Django",
          image:
            "https://static-00.iconduck.com/assets.00/django-icon-1606x2048-lwmw1z73.png",
        },
        {
          name: "Springboot",
          image:
            `${spring}`,
        },
      ],
    },
    {
      title: "Databases",
      skills: [
        {
          name: "MySQL",
          image:
            "https://cdn-icons-png.flaticon.com/512/5968/5968313.png",
        },
        {
          name: "MongoDB",
          image:
            "https://cdn.icon-icons.com/icons2/2415/PNG/512/mongodb_original_wordmark_logo_icon_146425.png",
        },
        {
          name: "Oracle",
          image:
            "https://logos-world.net/wp-content/uploads/2020/09/Oracle-Symbol.png",
        },
        {
          name: "PostgreSQL",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/993px-Postgresql_elephant.svg.png",
        },
        {
          name: "GraphQL",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/2048px-GraphQL_Logo.svg.png",
        },
      ],
    },
    {
      title: "Machine Learning",
      skills: [
        {
          name: "Tensorflow",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Tensorflow_logo.svg/1200px-Tensorflow_logo.svg.png",
        },
        {
          name: "PyTorch",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/PyTorch_logo_icon.svg/1200px-PyTorch_logo_icon.svg.png",
        },
        {
          name: "Numpy",
          image:
            "https://cdn.worldvectorlogo.com/logos/numpy-1.svg",
        },
        {
          name: "Pandas",
          image:
            "https://cdn.worldvectorlogo.com/logos/pandas.svg",
        },
        {
          name: "ML Models",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/d/d5/Hey_Machine_Learning_Logo.png",
        },
      ],
    },
    {
      title: "Others",
      skills: [
        {
          name: "GitHub",
          image:
            "https://cdn-icons-png.flaticon.com/512/536/536452.png",
        },
        {
          name: "AWS",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/2560px-Amazon_Web_Services_Logo.svg.png",
        },
        {
          name: "VS Code",
          image:
            "https://cdn.icon-icons.com/icons2/2107/PNG/512/file_type_vscode_icon_130084.png",
        },
        {
          name: "Linux",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Tux.svg/1200px-Tux.svg.png",
        },
        {
          name: "Data Structures and Algorithms",
          image:
            "https://user-images.githubusercontent.com/104568275/186131673-9ffff84c-21b4-421b-b956-643aa0fa7dbd.png",
        },
        {
          name: "OOP Concepts",
          image:
            "https://t4.ftcdn.net/jpg/01/08/96/57/360_F_108965707_UhpFxvquwgXVfWlFpg9vYGJfz8mmZ33u.jpg",
        },
        {
          name: "Software Development Life Cycle",
          image:
            `${sdlc}`,
        },
      ],
    },
];

export const experiences = [
  {
    id: 0,
    img: "https://media.licdn.com/dms/image/D560BAQE8M4sRW-0kbg/company-logo_200_200/0/1697828407648/infinite_options_llc_logo?e=2147483647&v=beta&t=6mWz4LZITEpp1aKl7SvxUonP2926vE8YaPt5dH9kgm4",
    role: "Software Engineer",
    company: "Infinite Options LLC",
    date: "July 2024 - Present",
    desc: " Developed RESTful APIs using Python and Flask, enhancing application responsiveness and data exchange efficiency, further augmented by advanced application monitoring and error detection using Prometheus and Grafana. Migrated backend services to AWS EC2 from Lambda functions and utilized AWS S3 for robust large object storage. These changes significantly enhanced system performance, scalability, and data-handling capabilities, ensuring high availability and integrity across distributed systems. Leveraged Linux extensively to manage EC2 instance configurations, write bash scripts for automation, and schedule cron jobs, significantly improving deployment processes and system stability through workflow automation. Implemented Docker to containerize backend applications, seamlessly integrated into CI/CD pipelines with GitHub Actions for automated Docker image creation, which facilitated faster and reduced deployment time by 20%. Conducted automated testing with PyTest to ensure code reliability and reduce deployment bugs, complemented by thorough GitHub code reviews to uphold code quality, standard compliance, and collaborative development practices. Enhanced security protocols and user authentication measures using JWT, ensuring robust system integrity and user data protection, while maintaining compliance with security best practices and regulatory requirements",
    skills: "Python, Flask, PyTest, Docker, AWS (EC2, S3, RDS), Linux, Prometheus, Grafana, GitHub Actions, JWT, CI/CD Pipelines, Bash Scripting, MySQL, SQL",
  },
  {
    id: 1,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeErvte9JXsdyWxjryLy1o516emLS6UFDUhRZ4Hgf8CA&s",
    role: "Teaching Assistant",
    company: "University of Dayton",
    date: "Aug 2023 - May 2024",
    desc: "Hold office hours to teach concepts of Database Management Systems. Leveraging Oracle 11g Express Addition to run SQL scripts and manage databases. Conduct help sessions to assist students with their doubts about Database Management systems. Grade students with their assignments, exams and projects",
    skills: "SQL, MySQL, Oracle, Database Management Systems",
  },
  {
    id: 2,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAMYaPt063CvF3zH9MaS1rbEoNoYOBAoygyw&usqp=CAU",
    role: "Full Stack Engineer",
    company: "Aekta Electricals",
    date: "Sept 2018 - July 2022",
    desc: "Developed and maintained scalable RESTful APIs using Java and Spring Boot, integrating external services to ensure seamless and efficient data communication between frontend and backend systems. Spearheaded the development of high-performance backend services using Python and Django, integrating PostgreSQL and Redis for optimized data retrieval, significantly improving system responsiveness by 15%. Developed and maintained robust, scalable, and highly efficient GraphQL APIs using Express.js on Node.js, enabling precise and optimized data fetching that significantly reduces payloads and enhances application performance. Engineered responsive single-page applications (SPAs) with React.js and Redux, delivering seamless user experiences across devices by effectively managing state and streamlining data flow. Implemented and optimized Jenkins for robust, streamlined continuous integration and delivery pipelines, drastically reducing deployment times and significantly enhancing code quality through advanced automated processes. Designed and optimized a comprehensive, adaptable AWS cloud infrastructure, utilizing EC2, CloudFront, Load Balancers, and Auto Scaling to ensure high availability, peak performance, and achieve 99.9% uptime. Enforced strict, advanced security measures by configuring AWS IAM roles, substantially augmenting system integrity and data protection with comprehensive access controls and robust security protocols.",
    skills: "Python, Django, PostgreSQL, Redis, Node.js, Express.js, GraphQL, React.js, Redux, Jenkins, Selenium, Amazon Web Services, MongoDB",
  },
  {
    id: 3,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAMYaPt063CvF3zH9MaS1rbEoNoYOBAoygyw&usqp=CAU",
    role: "Software Engineer Intern",
    company: "Akash Technolabs",
    date: "July 2019 - May 2020",
    desc: "Played a key role in the end-to-end development of a cross-platform cab booking application, handling front-end, back-end, and middleware with Google Maps API to enhance real-time tracking features and user experience. Designed and optimized database schemas with MySQL, managing data efficiently for passengers, drivers, and administrative functions, which ensured seamless data flow and integrity across the application components. Worked closely with a cross-functional team in an Agile environment to meet tight deadlines and deliver high-quality features, contributing to the project's success and consistently exceeding expectations.",
    skills: "Python, Flask, JavaScript, React.js, MySQL, Google Maps API, Selenium",
  }
];

export const educations = [
  {
    id: 0,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeErvte9JXsdyWxjryLy1o516emLS6UFDUhRZ4Hgf8CA&s",
    school: "University of Dayton",
    date: "Aug 2022 - May 2024",
    grade: "4.0 / 4.0 GPA",
    desc: "Advanced Programming and Data Structures, Database Management Systems - I & II, Algorithm Design, Machine Learning In Cybersecurity, Advanced Intelligent Systems and Deep Learning, Operating System",
    degree: "Master of Science, Computer Science",
  },
  {
    id: 1,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpHcbbJy0kdWRob57g9XIdsWiM_yDyFbr4dVY19u1Hnw&s",
    school: "Gujarat Technological University",
    date: "Aug 2016 - Sept 2020",
    grade: "3.5 / 4.0 GPA",
    desc: "Data Structures and Algorithms, Maths, Software Engineering, Operating Systems, Artificial Intelligence, Theory of Computations, Computer Networks",
    degree: "Bachelor of Engineering, Computer Engineering",
  },
];

export const projects = [
  {
    id: 0,
    title: "Connectify",
    date: "Feb 2024 - Mar 2024",
    description:
      "A chat application using MERN stack. Implemented real-time communication feature using Socket.io. ",
    image:
      `${connectify}`,
    tags: [
      "JavaScript",
      "React.js",
      "Node.Js",
      "Express.Js",
      "Zustand",
      "Tailwind CSS",
      "MongoDB"
    ],
    category: "web app",
    github: "https://github.com/saumya4751/Connectify",
  },
  {
    id: 1,
    title: "TrendyStore",
    date: "Nov 2020 - Jan 2021",
    description:
      "TrendyStore is a web application that allows users to search, filter and buy shoes and clothes at best price",
    image:
    `${shoestore}`,
    tags: [
      "Python",
      "Django",
      "JavaScript",
      "HTML",
      "CSS",
      "BootStrap",
      "SQLite3",
    ],
    category: "web app",
    github: "https://github.com/saumya4751/TrendyStore",
  },
  {
    id: 2,
    title: "Spam Filtering & Phishing URL Detection",
    date: "Aug 2023 - Sept 2023",
    description:
      "Developed the logic behind detecting spam emails and Phishing URLs",
    image:
      `${spam}`,
      tags: ["Python", "Tensorflow", "ImageDataGenerator", "Numpy", "Matplotlib"],
    category: "machine learning",
    github: "https://github.com/saumya4751/detecting_phishing_urls",
  },
  {
    id: 3,
    title: "Image Classification",
    date: "Sept 2023 - Oct 2023",
    description:
      "Built and trained a model that can classify images and detect which type of bird or painting an image contains",
    image:
      `${imageclassification}`,
    tags: ["Python", "Tensorflow", "Numpy", "Matplotlib", "ImageDataGenerator"],
    category: "machine learning",
    github: "https://github.com/saumya4751/image_classification/blob/main/Birds_image_classification.ipynb",
  },
  {
    id: 4,
    title: "RentPe",
    date: "Aug 2018 - Feb 2019",
    description:
      "Developed an Uber like web application, on which users can book a cab for their destination",
    image:
    `${rent}`,
    tags: [
      "Python",
      "Django",
      "MySQL",
      "JavaScript",
      "BootStrap",
      "HTML",
      "CSS",
    ],
    category: "web app",
    github: "https://github.com/saumya4751/RentPe",
  },
  {
    id: 5,
    title: "Web Scraping",
    date: "Oct 2022 - Nov 2022",
    description:
      "Worked on web scraping to visualize the data from 2 websites, IMDB and a Weather website",
    image:
    `${ws}`,
    tags: [
      "Python",
      "BeautifulSoup4",
      "Numpy",
      "Pandas",
      "Matplotlib",
    ],
    category: "other",
    github: "https://github.com/saumya4751/data_visualization",
  },
];